import { buildCountry, buildBrand, buildDeliveryDate } from 'apis/shared/query.helper'

const shippingLabelsQuery = argString =>
  [
    `{ shippingLabels(${argString}) {`,
    `shipmentNumber`,
    `shipmentDate`,
    `deliveryDate`,
    `shipper`,
    `status`,
    `statusData`,
    `} }`,
  ].join(' ')

// To be able to filter by shippers paginated results we should additionally query shippers from db
const paginatedShippingLabelsWithShippersQuery = labelsArgs =>
  [
    `{ shippingLabels: paginatedShippingLabels(${labelsArgs}) {`,
    `totalCount`,
    `allShipperNames`,
    `edges {`,
    `node {`,
    `shipmentNumber`,
    `shipmentDate`,
    `deliveryDate`,
    `shipper`,
    `trackingNumber }`,
    `cursor }`,
    `pageInfo {`,
    `startCursor`,
    `endCursor`,
    `hasNextPage`,
    `} } }`,
  ].join(' ')

const DEFAULT_PER_PAGE = 20

const brandArgString = data => {
  return data.brand ? `brands:${buildBrand(data.brand)},` : ''
}

const countriesArgString = data =>
  `countries:${buildCountry(data.continent, data.country, data.countries)},`

export const buildShippingLabelsQuery = data => {
  let argString = ''

  argString = argString.concat(countriesArgString(data))
  argString = argString.concat(brandArgString(data))

  argString = data.deliveryDateRange
    ? argString.concat(`deliveryDateRange:${buildDeliveryDate(data.deliveryDateRange)},`)
    : argString

  if (data.shipmentNumber || data.shipper) {
    argString += `filter: {`

    if (data.shipmentNumber) argString += `shipmentNumberContains: "${data.shipmentNumber}",`
    if (data.shipper) argString += `shipper: "${data.shipper}",`

    argString += `},`
  }

  if (data.missingLabels) {
    argString = argString.concat(`missingLabels:${data.missingLabels},`)

    return shippingLabelsQuery(argString)
  }

  if (data.pendingLabels) {
    argString = argString.concat(`pendingLabels:${data.pendingLabels},`)

    return shippingLabelsQuery(argString)
  }

  if (data.perPage === 'all') {
    return shippingLabelsQuery(argString)
  }

  // Pagination for Shipping Labels
  const perPage = data.perPage || DEFAULT_PER_PAGE

  const cursor = data.cursor
  const cursorDirection = data.cursorDirection

  const position = cursorDirection === 'before' ? 'last' : 'first'
  argString += `${position}:${perPage},`

  if (cursor) argString += `${cursorDirection}:"${cursor}",`

  return paginatedShippingLabelsWithShippersQuery(argString)
}
